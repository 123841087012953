// Variables
$button-border-radius: 0.5em;

// Font sizes

$title: 2rem;
$subtitle: 1.2rem;

$mobile-title: 1.2rem;
$mobile-subtitle: 0.9rem;

$font-weight-heading: 600;
$font-weight-subheading: 500;

$line-height: 1.5em;

// Margins
$margin-1: 7em;
$margin-2: 5em;
$margin-3: 0.5em;

// Colors

$primary-color: #056cb3;

$secondary-color: #ffd525;
$darker-secondary-color: #d6ac00;
$grey-color: #a9a9ab;
$light-grey-color: #ccc;
$black-color: #333;
$white-color: #fff;

$muted-color: #f8f8fa;
$light-bg: #efefef;

$hover-color: #ffffdd;

$success-color: #32d296;
$danger-color: #dc3545;
$warning-color: #faa05a;

$border-radius: 0.2em;
