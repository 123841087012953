@import "./shared/styles/Variables.scss";

//Shared components
// Modals
.modal-style {
  .uk-modal-title {
    font-size: 1.2rem;
    font-weight: 600;
  }
  .sub-heading {
    margin-bottom: 0.2em;
    text-transform: uppercase;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.back {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: $primary-color;
}

.uk-input,
.uk-select,
.uk-textarea,
button {
  border-radius: $border-radius !important;
  text-transform: none !important;
}

.uk-card-outlined {
  border: 1px solid $light-bg;
}

.uk-card-muted {
  background-color: $muted-color;
}

.uk-card {
  border-radius: $border-radius;
}

th {
  text-transform: capitalize !important;
}

label {
  text-transform: capitalize !important;
}

.clear-filter {
  background-color: transparent;
  border: none;
  text-transform: none;
  color: $primary-color;
  margin: 0em;
  padding: 0;
  font-weight: $font-weight-heading;
  &:hover {
    cursor: pointer;
  }
}
