@import "./shared//styles/Variables.scss";

// @media print {
//   //
//   .print {
//     position: absolute;
//     display: block !important;
//     background-color: red;
//   }
// }

*,
*::after,
*::before {
  box-sizing: border-box;
}
html {
  font-size: 0.91rem;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Prevent modal from snapping page to top.
body.modal-open {
  overflow: visible;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.uk-offcanvas-bar-light {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -270px;
  box-sizing: border-box;
  width: 270px;
  padding: 20px 20px;
  background: #f8f8f8;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.uk-offcanvas-bar-animation {
  transition: left 0.3s ease-out;
}
@media (min-width: 960px) {
  .uk-offcanvas-bar-light {
    left: -350px;
    width: 350px;
    padding: 40px 40px;
  }
}
.uk-offcanvas-flip .uk-offcanvas-bar-light {
  left: auto;
  right: -270px;
}
.uk-offcanvas-flip .uk-offcanvas-bar-animation {
  transition-property: right;
}
.uk-offcanvas-flip .uk-open > .uk-offcanvas-bar-light {
  left: auto;
  right: 0;
}
@media (min-width: 960px) {
  .uk-offcanvas-flip .uk-offcanvas-bar-light {
    right: -350px;
  }
}
.uk-offcanvas-flip .uk-offcanvas-reveal .uk-offcanvas-bar-light {
  left: auto;
  right: 0;
}
.uk-offcanvas-reveal .uk-offcanvas-bar-light {
  left: 0;
}
.uk-open > .uk-offcanvas-bar-light {
  left: 0;
}

.uk-section-muted {
  background-color: $light-bg;
}

.uk-button {
  padding: 0em 1em;
  line-height: 2.2em;
  border: 0;
  font-weight: $font-weight-heading;
}

// Scrollbar
*::-webkit-scrollbar {
  width: 0.6em;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.2);
}

*::-webkit-scrollbar-thumb {
  background-color: $grey-color;
  outline: none;
  border-radius: $border-radius;
}
