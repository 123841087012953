@import "../../shared/styles/Variables.scss";
.login {
  height: auto;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 967px) {
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .container {
    padding: 3em;
    @media screen and (min-width: 967px) {
      padding: 5em;
    }
  }
  .left {
    background-color: $primary-color;
    .title {
      color: #fff;
      font-size: 2rem;
    }
    .moto {
      color: #fff;
    }
  }
  .right {
    button {
      background-color: $primary-color;
      color: #fff;
      padding: 0.2em 1em;
    }
  }
}
